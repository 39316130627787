import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const IndexPage = () => {
  return (
    <>
      <Seo title="Home" />
    </>
  );
};

IndexPage.Layout = Layout;

export default IndexPage;
